<template>
  <v-tabs v-bind="$attrs" v-model="selectedIndex">
    <v-tabs-slider color="error"></v-tabs-slider>
    <v-tab v-for="(item, index) in items" :key="index" @click="select(index)">
      {{ item.code }}
    </v-tab>
  </v-tabs>
</template>
<script>
export default {
  name: 'DivisionTabs',
  props: {
    value: null,
    all: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
      selectedIndex: -1,
    };
  },
  methods: {
    select(index) {
      this.selectedIndex = index;
      const selectedDivision = this.items[this.selectedIndex];
      localStorage.setItem('selectedDivision', selectedDivision.id);
      this.$emit('input', selectedDivision);
    },
    async getData() {
      this.items = await this.apiList({ resource: 'common/division' });
      if (this.all) {
        if (!this.items.some((el) => el.id === 0)) {
          this.items.splice(0, 0, { id: 0, code: 'Alle' });
        }
      }
      const selectedId = Number.parseInt(
        localStorage.getItem('selectedDivision')
      );
      for (let i = 0; i < this.items.length; ++i) {
        if (this.items[i].id === selectedId) {
          this.select(i);
          break;
        }
      }
    },
  },
  watch: {
    all() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
